@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  :root {
    --primary-color: #38353A;
    --secondary-color: #4A4649;
    --accent-color: #FF6B6B;
    --neutral-light: #F5F5F5;
    --neutral-dark: #333333;
    --white: #ffffff;
    --gray-300: #E0E0E0;
    --shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    --transition: all 0.3s ease;
    --font-heading: 'JetBrains Mono', monospace;
    --font-body: 'Inter', sans-serif;
  }

  html {
    @apply bg-[var(--neutral-light)];
  }

  body {
    @apply m-0 font-sans antialiased bg-[var(--neutral-light)] text-[var(--neutral-dark)];
  }
}

@layer components {
  /* Button Styles */
  .btn-primary {
    @apply bg-[var(--primary-color)] text-white px-4 py-2 rounded hover:bg-[var(--secondary-color)] transition-colors;
  }

  .btn-secondary {
    @apply bg-transparent text-[var(--primary-color)] border border-[var(--primary-color)] px-4 py-2 rounded hover:bg-[var(--primary-color)] hover:text-white transition-colors;
  }

  /* Form Styles */
  .form-input {
    @apply block w-full rounded-md border-gray-300 shadow-sm 
    focus:ring-[var(--accent-color)] focus:border-[var(--accent-color)]
    placeholder-gray-400;
  }

  /* Container Styles */
  .page-container {
    @apply py-8 px-4 sm:px-6 lg:px-8;
  }

  .content-section {
    @apply bg-white rounded-lg shadow-md p-6 sm:p-8;
  }

  /* Typography */
  .heading-1 {
    @apply text-3xl font-bold text-[var(--primary-color)] mb-6;
  }

  .heading-2 {
    @apply text-xl font-semibold text-[var(--primary-color)] mb-4;
  }

  /* Card Styles */
  .card {
    @apply bg-white rounded-lg shadow-md overflow-hidden
    transition-transform hover:-translate-y-1 hover:shadow-lg;
  }

  /* Navigation Styles */
  .nav-link {
    @apply text-white hover:text-[var(--accent-color)] transition-colors px-4 py-2;
  }

  /* Form Container */
  .form-container {
    @apply max-w-md mx-auto bg-white p-8 rounded-lg shadow-md;
  }

  /* Form Group */
  .form-group {
    @apply space-y-1;
  }

  .form-label {
    @apply block text-sm font-medium text-gray-700;
  }

  /* Success and Error Messages */
  .success-message {
    @apply bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4;
  }

  .error-message {
    @apply bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4;
  }

  .blog-image {
    @apply w-full h-48 object-contain bg-[var(--neutral-light)] p-4 
           transition-transform duration-300 hover:scale-105;
  }

  .featured-blog-image {
    @apply w-full h-64 md:h-full object-contain bg-[var(--neutral-light)] p-4 
           transition-transform duration-300 hover:scale-105;
  }

  .prose {
    @apply text-gray-800;
  }

  .prose h2 {
    @apply text-2xl font-bold text-[var(--primary-color)] mt-8 mb-4;
  }

  .prose h3 {
    @apply text-xl font-semibold text-[var(--primary-color)] mt-6 mb-3;
  }

  .prose p {
    @apply mb-4 leading-relaxed;
  }

  .prose .lead {
    @apply text-xl text-gray-600 mb-6;
  }

  .prose ul, .prose ol {
    @apply mb-6 pl-6;
  }

  .prose li {
    @apply mb-2;
  }

  .prose ul li {
    @apply list-disc;
  }

  .prose ol li {
    @apply list-decimal;
  }

  .prose blockquote {
    @apply pl-4 border-l-4 border-[var(--accent-color)] italic my-6 text-gray-600;
  }

  .prose .info-box {
    @apply bg-[var(--neutral-light)] p-6 rounded-lg my-6;
  }

  .prose .info-box h3 {
    @apply text-lg font-bold text-[var(--primary-color)] mt-0 mb-2;
  }

  .prose .info-box p {
    @apply m-0 text-gray-600;
  }

  .hero-heading {
    @apply text-4xl md:text-5xl font-bold text-[var(--primary-color)] mb-6;
  }

  .stats-card {
    @apply text-center p-6 bg-white rounded-lg shadow-md;
  }

  .testimonial-card {
    @apply bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow;
  }

  .btn-accent {
    @apply px-6 py-3 bg-[var(--accent-color)] text-white rounded-lg
    hover:bg-opacity-90 transition-colors duration-300
    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[var(--accent-color)];
  }

  .service-tab {
    @apply px-6 py-3 rounded-full transition-all duration-300;
  }

  .service-tab.active {
    @apply bg-[var(--primary-color)] text-white shadow-lg;
  }

  .service-tab:not(.active) {
    @apply bg-gray-100 text-gray-600 hover:bg-gray-200;
  }

  .metric-card {
    @apply bg-white p-4 rounded-lg text-center shadow-sm
    hover:shadow-md transition-shadow duration-300;
  }

  .timeline-dot {
    @apply w-4 h-4 rounded-full bg-[var(--accent-color)];
  }

  .timeline-line {
    @apply border-l-2 border-[var(--accent-color)] h-full;
  }

  .team-member-card {
    @apply content-section text-center hover:shadow-lg transition-shadow;
  }

  .team-member-image {
    @apply w-32 h-32 rounded-full mx-auto mb-4 
           border-4 border-[var(--neutral-light)]
           transition-transform hover:scale-105 duration-300;
  }
}
